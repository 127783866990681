import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from "react";

// importing components
import { IndexComponent } from "./Components/IndexComponent";
import Footer from "./Components/Footer";

// Importing Pages
import ProjectPage from "./pages/ProjectPage";
import ProjectsPage from "./pages/ProjectsPage";
import ResumePage from "./pages/ResumePage";
import AboutPage from "./pages/AboutPage";
import ContactMePage from "./pages/ContactMePage";



// Importing databases file
// import ProjectsDatabase from "./Components/ProjectsDatabase";

function App() {
  const [selectedproject, setselectedproject] = useState(null);
  // const SiteUrl = 'http://127.0.0.1:8000/'
  const SiteUrl = 'https://mohitji.site'

  const [ProjectsDatabase, setProjectsDatabase] = useState([]);
  useEffect(() => {
    fetch('/api/projects/')
      .then(response => response.json())
      .then(data => setProjectsDatabase(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);



  function PortfolioPage(id) {
    setselectedproject(id);
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<IndexComponent ProjectsDatabase={ProjectsDatabase} PortfolioPage={PortfolioPage} />} />
          <Route path="/project/:id" element={<ProjectPage ProjectsDatabase={ProjectsDatabase}  SiteUrl={SiteUrl} />} />
          <Route path="/resume" element={<ResumePage />} />
          <Route path="/projects" element={<ProjectsPage ProjectsDatabase={ProjectsDatabase} SiteUrl={SiteUrl} />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactMePage />} />

        </Routes>
      </Router>
      <Footer />
    </>

  );
}

export default App;
